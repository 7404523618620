@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100;300;400;700&display=swap');
@import './theme.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .auth-layout {
    @apply pb-[40px] pl-[15px] pr-[15px] pt-[10px] xs:mx-auto xs:my-0 xs:w-[560px] xs:px-0 xs:pb-[70px] md:w-[640px];
  }
}

@layer base {
  body {
    font-family: 'Lato', 'Noto Sans TC', 'Noto Sans SC', sans-serif;
    font-size: 16px;
    margin: 0;
    color: var(--text);
    background-color: var(--background);

    /* scrollbar */
    ::-webkit-scrollbar {
      width: 15px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: var(--scrollbar);
      background-clip: content-box;
      border-radius: 9px;
      border: 5px solid transparent;
    }
  }
  button {
    font-family: inherit;
  }
}

/* disable spin button of input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
