html {
  --text: theme(colors.dark.700);
  --background: theme(colors.light.100);
  --scrollbar: theme(colors.grey.500/30%);

  --calendar-background: theme(colors.white);
  --calendar-foreground: theme(colors.black.900);
  --calendar-weekdays: theme(colors.grey.700);
  --calendar-hover-foreground: theme(colors.black.900);
  --calendar-hover-background: theme(colors.light.300);
  --calendar-selected-foreground: theme(colors.white);
  --calendar-selected-background: theme(colors.turquoise);
  --calendar-current-border: theme(colors.black.900);
  --calendar-unavailable: theme(colors.black.500);
}
html[class='dark'] {
  --text: theme(colors.white);
  --background: theme(colors.black.900);
  --scrollbar: theme(colors.grey.300/30%);

  --calendar-background: theme(colors.black.500);
  --calendar-foreground: theme(colors.white);
  --calendar-weekdays: theme(colors.grey.700);
  --calendar-hover-foreground: theme(colors.black.900);
  --calendar-hover-background: theme(colors.grey.300);
  --calendar-selected-foreground: theme(colors.black.900);
  --calendar-selected-background: theme(colors.lime);
  --calendar-current-border: theme(colors.white);
  --calendar-unavailable: theme(colors.dark.500);
}
