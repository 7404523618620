.react-calendar {
  max-width: 272px;
  z-index: 10;
  overflow: hidden;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--calendar-background);

  button {
    margin: 0;
    border: 0;
    outline: none;
  }
}

.react-calendar__navigation {
  height: 31px;
  padding: 5px 20px;
  color: var(--calendar-foreground);
  background-color: var(--calendar-background);

  display: flex;
  align-items: center;
}

.react-calendar__navigation__arrow {
  width: 44px;
  background-color: initial;
  color: inherit;
}

.react-calendar__navigation__label {
  width: 132px;
  background-color: initial;
  color: inherit;
}

.react-calendar__viewContainer {
  padding: 0 10px 10px 10px;
}

.react-calendar__month-view__weekdays {
  height: 30px;
  align-items: center;
  text-align: center;
  font-size: 12px;
  color: var(--calendar-weekdays);

  abbr[title] {
    text-decoration: none !important;
  }
}

.react-calendar__tile {
  border-radius: 50%;
  color: var(--calendar-foreground);
  background-color: var(--calendar-background);

  &:hover {
    color: var(--calendar-hover-foreground);
    background-color: var(--calendar-hover-background);
  }
}

.react-calendar__tile--active {
  color: var(--calendar-selected-foreground) !important;
  background-color: var(--calendar-selected-background) !important;
}

.react-calendar__month-view__days__day {
  height: 36px;
}

.react-calendar__tile--now {
  border: 1px solid var(--calendar-current-border) !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--calendar-unavailable);
}

.react-calendar__year-view__months__month {
  height: 50px;
}

.react-calendar__decade-view__years__year {
  height: 50px;
}

.react-calendar__century-view__decades__decade {
  height: 50px;
}
